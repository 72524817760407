var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\triprequests\\list.2438')))]),_c('v-spacer'),(_vm.user.adminAccess && _vm.user.adminAccess.trip_requests >= _vm.ACCESS.WRITE)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('src\\views\\triprequests\\list.5423')))],1):_vm._e()],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.common",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.common ? 'Да' : 'Нет')+" ")]}},{key:"item.blogger",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.blogger ? ((item.blogger.social) + " (" + (item.blogger.firstname) + " " + (item.blogger.lastname) + ")") : '-')+" ")]}},{key:"item.trip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip ? item.trip.name : '-')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }